import BaseComponent from "../../components/BaseComponent";
import VerticalLayout from "../../components/layouts/VerticalLayout";
import DateDiff from 'date-diff';
import InputField from "../../lib/components/inputFields/InputField";
import PrimaryButton from "../../lib/components/buttons/PrimaryButton";
import {Clock, Plus, Trash} from "react-feather";
import {toast} from "react-toastify";
import HorizontalLayout from "../../components/layouts/HorizontalLayout";

export default class TimeCalculator extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      totalDifference: 0, entries: [{startTime: '', endTime: '', difference: 0}]
    };
  }

  addNewEntry() {
    const newEntry = {startTime: '', endTime: '', difference: 0};
    this.setState(prevState => ({
      entryCount: prevState.entryCount + 1, entries: [...prevState.entries, newEntry]
    }));
  }

  calculateDifference(index) {
    const {entries} = this.state;
    const startTime = entries[index].startTime;
    const endTime = entries[index].endTime;

    if (startTime !== '' && endTime !== '') {
      const startHour = parseInt(startTime.substring(0, 2), 10);
      const startMinute = parseInt(startTime.substring(2), 10);
      const endHour = parseInt(endTime.substring(0, 2), 10);
      const endMinute = parseInt(endTime.substring(2), 10);

      const startDate = new Date();
      startDate.setHours(startHour);
      startDate.setMinutes(startMinute);

      const endDate = new Date();
      endDate.setHours(endHour);
      endDate.setMinutes(endMinute);

      const diff = new DateDiff(endDate, startDate);
      entries[index].difference = diff.hours();
      this.setState({entries});
      this.updateTotalDifference();
    }
  }

  updateTotalDifference() {
    const {entries} = this.state;
    let totalDifference = 0;
    entries.forEach(entry => {
      totalDifference += entry.difference;
    });
    this.setState({totalDifference});
  }

  updateStartTime(index, value) {
    const {entries} = this.state;
    entries[index].startTime = value;
    this.setState({entries});
  }

  updateEndTime(index, value) {
    const {entries} = this.state;
    entries[index].endTime = value;
    this.setState({entries});
  }

  render() {
    const {entries, totalDifference} = this.state;

    return (<VerticalLayout className="min-h-screen items-center justify-center">
      <div className="grid grid-cols-7 items-center border rounded-xl py-2 w-fit gap-4">
        {entries.map((entry, index) => (<><HorizontalLayout key={index} className=' pl-2 col-span-5'>
          <InputField placeholder='von' value={entry.startTime}
                      onChange={(e) => this.updateStartTime(index, e.target.value)}/>
          <InputField placeholder='bis' value={entry.endTime}
                      onBlur={() => this.calculateDifference(index)}
                      onChange={(e) => this.updateEndTime(index, e.target.value)}/>
        </HorizontalLayout>
          <HorizontalLayout className='col-span-1'>
            <Clock/>{entry.difference} h
          </HorizontalLayout>
          {index === 0 ? <></> : <div className='col-span-1 flex justify-center'>
            <button onClick={(e) => {
              this.state.entries.splice(index, 1);
              this.setState(this.state);
            }} className='bg-colors-red-600 rounded-lg p-0.5'><Trash className='p-0.5 text-colors-white'/>
            </button>
          </div>}
          <hr className='col-span-full last:hidden'/>
        </>))}
      </div>
      <p>Total Zeit: {totalDifference} h</p>
      <PrimaryButton onClick={() => this.addNewEntry()}><Plus/></PrimaryButton>
    </VerticalLayout>);
  }
}
