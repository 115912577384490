import VerticalLayout from '../components/layouts/VerticalLayout';
import BaseComponent from '../components/BaseComponent';
import GridLayout from '../components/layouts/GridLayout';
import GridItem from '../components/layouts/GridItem';
import shortid from "shortid";

import Contact from "./home/Contact";

class AboutMePage extends BaseComponent {

  render() {
    let thingsIKnow = ['Photography', 'HTML/CSS', 'Project manager', 'Video editing', 'UI/UX Design', 'Python', 'Java',
      'Javascript', '.Net', 'PHP', 'Android', 'Symfony', 'React & NextJS'];

    // return <VerticalLayout className='text-black dark:text-white p-0 bg-gradient-to-r from-deep-purple-200 via-colors-gray-300 to-colors-cyan-300 dark:bg-gradient-to-tr dark:from-gray-800 dark:via-colors-slate-800 dark:to-black'>
    return <VerticalLayout className={'mt-8'}>
      <GridLayout id='aboutPage' className='grid-cols-1 lg:grid-cols-2 gap-x-32 gap-y-4 container mx-auto min-h-screen'>
        <GridItem className=' gap-2 self-end md:self-center flex flex-col justify-center text-5xl animate__animated animate__fadeInUp'>
          <h2 className=''>Hi! I'm</h2>
          <div className='inline bg-clip-text bg-gradient-to-r from-colors-cyan-800 to-colors-black via-colors-violet-800'>
            <h1 className='text-colors-transparent font-bold'>Farzad Habibi</h1>
          </div>
          <h3 className='text-4xl font-medium'>and I am a Software Developer</h3>
          <span className='text-colors-teal-800 text-lg'>{'{'}Java, PHP, JS, Python, .Net...{'}'}</span>
        </GridItem>
        <GridItem className='self-start mt-6 md:self-center md:mt-0 animate__animated animate__fadeInUp'>
          <img alt='me' className='w-full rounded-full shadow-2xl drop-shadow-lg shadow-colors-gray-900' src='/images/hacker.jpg'/>
        </GridItem>
        <GridItem className='animate__animated animate__fadeInUp'>
          <h2 className='text-5xl'>About Me</h2>
          I came to switzerland in 2015 when I was 13. By the age of 20 I finished my apprenticeship as a software
          developer and started a new
          job
          mainly designing and building new websites.<br/>
          In my spear time I really love to dance. Whenever I listen to music my mind loses control over my body and it
          starts to move:)<br/>
          Lately I've been creating my own videos of the moments I spend with my friends. The idea is to capture as much
          footage as possible and
          then
          add a suitable song and sync the footage with the song. I often rewatch my videos when I get bored or don't
          have much to do.
        </GridItem>
        <GridItem className='flex items-start animate__animated animate__fadeInUp'>
          <div className='flex flex-wrap gap-3'>
            {thingsIKnow.map((child) => {
              return <div key={shortid()} className='hover:scale-110 transition cursor-pointer bg-colors-white px-2 py-1.5 bg-white rounded-lg font-medium'>{child}</div>
            })}
          </div>
        </GridItem>
      </GridLayout>
      <VerticalLayout id='workPage' className='container mx-auto px-4 min-h-screen justify-center relative'>
        {/*<img className='absolute top-32 right-32 bottom-0' src={GradientImage} alt=''/>*/}
        <h1 className='text-4xl lg:text-8xl font-medium z-10 inline text-transparent bg-clip-text bg-gradient-to-r from-colors-cyan-800 to-colors-black via-colors-violet-800'>
          A developer passionate<br/> about creating useful<br/> user friendly apps
        </h1>
      </VerticalLayout>

      <Contact />

    </VerticalLayout>
  }
}

export default AboutMePage;