import BaseComponent from "../components/BaseComponent";
import ToolCard from "./tools/ToolCard";
import HorizontalLayout from "../components/layouts/HorizontalLayout";
import clocksImg from './tools/images/clocks.jpg'
import sandClock from './tools/images/sandclock.jpg'

export default class ToolsPage extends BaseComponent {

  render() {

    return <div className="flex justify-center items-center gap-3 min-h-screen text-colors-black py-20">
      <HorizontalLayout className="justify-center gap-4 animate__animated animate__fadeInUp">
        {/*<ToolCard header="Dictionary"*/}
        {/*          description="Displays a random word and its definition"*/}
        {/*          link="/tools/dictionary"*/}
        {/*          image="/images/dictionary.jpg"/>*/}

        <ToolCard header="Time Calculator"
                  description="Displays the difference between two dates"
                  link="/tools/timecalculator"
                  image={clocksImg}/>
        <ToolCard header="Time Tracker"
                  description="Keep track of which tasks you did for how long "
                  link="/tools/timerecorder"
                  image={sandClock}/>

        {/*<ToolCard header="Weather"*/}
        {/*          description="Check the weather from every city"*/}
        {/*          link="/tools/weather"*/}
        {/*          image={weatherImg}/>*/}
      </HorizontalLayout>
    </div>
  }
}